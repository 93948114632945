import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
  Container, 
  Typography, 
  Grid, 
  Card, 
  CardMedia, 
  CardContent,
  Button,
  Chip,
  TextField,
} from '@material-ui/core';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '75%', // 4:3 aspect ratio
    cursor: 'pointer',
  },
  cardContent: {
    flexGrow: 1,
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(2),
  },
  filterChip: {
    margin: theme.spacing(0.5),
  },
}));

// Sample gallery items (replace with your own)
const galleryItems = [
  { id: 1, type: 'image', src: 'https://example.com/image1.jpg', title: 'Our First Date', category: 'Dates' },
  { id: 2, type: 'image', src: 'https://example.com/image2.jpg', title: 'Beach Vacation', category: 'Vacations' },
  { id: 3, type: 'video', src: 'https://example.com/video1.mp4', title: 'Anniversary Celebration', category: 'Anniversaries' },
  // Add more items as needed
];

const categories = ['All', ...new Set(galleryItems.map(item => item.category))];

function GalleryPage() {
  const classes = useStyles();
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [filter, setFilter] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');

  const filteredItems = galleryItems
    .filter(item => filter === 'All' || item.category === filter)
    .filter(item => 
      item.title.toLowerCase().includes(searchTerm.toLowerCase()) || 
      item.category.toLowerCase().includes(searchTerm.toLowerCase())
    );

  const handleItemClick = (index) => {
    setActiveIndex(index);
    setLightboxOpen(true);
  };

  return (
    <Container className={classes.container} maxWidth="lg">
      <Typography variant="h2" align="center" gutterBottom>
        Our Gallery
      </Typography>
      Note: This page is a work in progress. I am working on an algorithm that would help us sort through our countless valuable pictures. At this point this is a showcase of the page without the pictures.

      <TextField
        fullWidth
        margin="normal"
        variant="outlined"
        label="Search memories"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      
      <div className={classes.filterContainer}>
        {categories.map((category) => (
          <Chip
            key={category}
            label={category}
            onClick={() => setFilter(category)}
            color={filter === category ? 'primary' : 'default'}
            className={classes.filterChip}
          />
        ))}
      </div>

      <Grid container spacing={4}>
        {filteredItems.map((item, index) => (
          <Grid item key={item.id} xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <CardMedia
                className={classes.cardMedia}
                image={item.type === 'image' ? item.src : 'https://example.com/video-thumbnail.jpg'}
                title={item.title}
                onClick={() => handleItemClick(index)}
              />
              <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="h5" component="h2">
                  {item.title}
                </Typography>
                <Typography>
                  {item.category}
                </Typography>
                {item.type === 'video' && (
                  <Button size="small" color="primary" href={item.src} target="_blank">
                    Watch Video
                  </Button>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {lightboxOpen && (
        <Lightbox
          mainSrc={filteredItems[activeIndex].src}
          nextSrc={filteredItems[(activeIndex + 1) % filteredItems.length].src}
          prevSrc={filteredItems[(activeIndex + filteredItems.length - 1) % filteredItems.length].src}
          onCloseRequest={() => setLightboxOpen(false)}
          onMovePrevRequest={() => setActiveIndex((activeIndex + filteredItems.length - 1) % filteredItems.length)}
          onMoveNextRequest={() => setActiveIndex((activeIndex + 1) % filteredItems.length)}
          imageTitle={filteredItems[activeIndex].title}
          imageCaption={filteredItems[activeIndex].category}
        />
      )}
    </Container>
  );
}

export default GalleryPage;