import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Grid,
  Collapse,
  TextField,
} from "@material-ui/core";
import { Favorite, ExpandMore, FavoriteBorder } from "@material-ui/icons";
import { red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardContent: {
    flexGrow: 1,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  newMessageForm: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

// Sample messages (replace with your own)
const initialMessages = [
  {
    id: 1,
    date: "2023-06-15",
    title: "Happy Anniversary!",
    content:
      "Three years of love, laughter, and unforgettable moments. You make every day special, and I'm so grateful for you.",
  },
  {
    id: 2,
    date: "2023-05-01",
    title: "Just Because",
    content:
      "I was thinking about you today and wanted to remind you how much you mean to me. Your smile brightens my world.",
  },
  {
    id: 3,
    date: "2023-04-14",
    title: "Date Night Excitement",
    content:
      "Looking forward to our date night tonight! You always know how to make every moment together feel magical.",
  },
];

function MessagesPage() {
  const classes = useStyles();
  const [messages, setMessages] = useState(initialMessages);
  const [expanded, setExpanded] = useState({});
  const [newMessage, setNewMessage] = useState({ title: "", content: "" });
  const [likes, setLikes] = useState({});

  const handleExpandClick = (id) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [id]: !prevExpanded[id],
    }));
  };

  const handleLike = (id) => {
    setLikes((prevLikes) => ({
      ...prevLikes,
      [id]: (prevLikes[id] || 0) + 1,
    }));
  };

  const handleNewMessageChange = (event) => {
    const { name, value } = event.target;
    setNewMessage((prevMessage) => ({
      ...prevMessage,
      [name]: value,
    }));
  };

  const handleNewMessageSubmit = (event) => {
    event.preventDefault();
    const newId = Math.max(...messages.map((m) => m.id)) + 1;
    const currentDate = new Date().toISOString().split("T")[0];
    setMessages([{ id: newId, date: currentDate, ...newMessage }, ...messages]);
    setNewMessage({ title: "", content: "" });
  };

  return (
    <Container className={classes.container} maxWidth="md">
      <Typography variant="h2" align="center" gutterBottom>
        Love Letters & Messages
      </Typography>
        Note: The messages reset when the page is refreshed. This is a work in progress. The messages in here are not real, just some random ones I'm using to test the website.

      <form
        className={classes.newMessageForm}
        onSubmit={handleNewMessageSubmit}
      >
        <TextField
          fullWidth
          margin="normal"
          name="title"
          label="Message Title"
          value={newMessage.title}
          onChange={handleNewMessageChange}
          required
        />
        <TextField
          fullWidth
          margin="normal"
          name="content"
          label="Message Content"
          multiline
          rows={4}
          value={newMessage.content}
          onChange={handleNewMessageChange}
          required
        />
        <Button type="submit" variant="contained" color="primary">
          Add New Message
        </Button>
      </form>

      <Grid container spacing={4}>
        {messages.map((message) => (
          <Grid item key={message.id} xs={12}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography variant="h5" component="h2">
                  {message.title}
                </Typography>
                <Typography color="textSecondary" gutterBottom>
                  {message.date}
                </Typography>
                <Collapse
                  in={expanded[message.id]}
                  timeout="auto"
                  unmountOnExit
                >
                  <Typography paragraph>{message.content}</Typography>
                </Collapse>
              </CardContent>
              <CardActions disableSpacing>
                <Button
                  size="small"
                  color="secondary"
                  startIcon={
                    likes[message.id] ? (
                      <Favorite style={{ color: red[500] }} />
                    ) : (
                      <FavoriteBorder />
                    )
                  }
                  onClick={() => handleLike(message.id)}
                >
                  Like {likes[message.id] > 0 && `(${likes[message.id]})`}
                </Button>
                <Button
                  className={classes.expand}
                  onClick={() => handleExpandClick(message.id)}
                  aria-expanded={expanded[message.id]}
                  aria-label="show more"
                >
                  <ExpandMore />
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default MessagesPage;
