import React, { useState } from 'react';
import { Typography, Button, Container, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FavoriteIcon from '@material-ui/icons/Favorite';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
}));

function LandingPage() {
  const classes = useStyles();
  const [loveClicks, setLoveClicks] = useState(0);

  const handleLoveClick = () => {
    setLoveClicks(loveClicks + 1);
  };

  return (
    <main>
      <div className={classes.heroContent}>
        <Container maxWidth="sm">
          <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
            Happy 3rd Anniversary My Bootiful Beanie Weenie Bean!
          </Typography>
          <Typography variant="h5" align="center" color="textSecondary" paragraph>
            Three wonderful years of love, laughter, and unforgettable moments.
            This website is a tribute to our journey together.
          </Typography>
          <Typography variant="h6" align='center' paragraph>
            This website was made with love by Fishie {"<"}3. Note that many features are still a work in progress since my main focus was on the real surprise I am going to give you. This website is scheduled to go live on 6/9/2024.<br /><b>For the best viewing experience, open this website on a laptop.</b>
          </Typography>
          <div className={classes.heroButtons}>
            <Grid container spacing={2} justify="center">
              <Grid item>
                <Button variant="contained" color="primary" onClick={handleLoveClick}>
                  <FavoriteIcon /> Show Love ({loveClicks})
                </Button>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
      <Container maxWidth="md">
        <Grid container spacing={4}>
          {['Timeline', 'Gallery (Work in progress)', 'Messages (Work in progress)', 'Memories (Work in progress)', 'Playlist (Work in progress)'].map((section) => (
            <Grid item key={section} xs={12} sm={6} md={4}>
              <Paper className={classes.card}>
                <Typography gutterBottom variant="h5" component="h2">
                  {section}
                </Typography>
                <Typography>
                  Explore our {section.toLowerCase()} and relive our precious moments together.
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </main>
  );
}

export default LandingPage;