import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Grid,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  IconButton,
} from '@material-ui/core';
import { Add, Favorite, FavoriteBorder, EmojiEmotions } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardContent: {
    flexGrow: 1,
  },
  addButton: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

// Sample memories (replace with your own)
const initialMemories = [
  { id: 1, content: 'Our first date at the coffee shop', tags: ['First Date', 'Coffee'], likes: 0, isJoke: false },
  { id: 2, content: 'Why did the scarecrow win an award? He was outstanding in his field!', tags: ['Joke', 'Laughter'], likes: 0, isJoke: true },
  { id: 3, content: 'Surprise picnic in the park', tags: ['Surprise', 'Outdoors'], likes: 0, isJoke: false },
];

function MemoriesPage() {
  const classes = useStyles();
  const [memories, setMemories] = useState(initialMemories);
  const [open, setOpen] = useState(false);
  const [newMemory, setNewMemory] = useState({ content: '', tags: '', isJoke: false });
  const [filter, setFilter] = useState('');

  const filteredMemories = memories.filter(memory => 
    memory.content.toLowerCase().includes(filter.toLowerCase()) ||
    memory.tags.some(tag => tag.toLowerCase().includes(filter.toLowerCase()))
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setNewMemory({ content: '', tags: '', isJoke: false });
  };

  const handleNewMemoryChange = (event) => {
    const { name, value } = event.target;
    setNewMemory((prevMemory) => ({
      ...prevMemory,
      [name]: value,
    }));
  };

  const handleNewMemorySubmit = () => {
    const newId = Math.max(...memories.map((m) => m.id)) + 1;
    const tagsArray = newMemory.tags.split(',').map(tag => tag.trim());
    setMemories([
      { id: newId, ...newMemory, tags: tagsArray, likes: 0 },
      ...memories,
    ]);
    handleClose();
  };

  const handleLike = (id) => {
    setMemories(memories.map(memory => 
      memory.id === id ? { ...memory, likes: memory.likes + 1 } : memory
    ));
  };

  return (
    <Container className={classes.container} maxWidth="md">
      <Typography variant="h2" align="center" gutterBottom>
        Our Favorite Memories & Inside Jokes
      </Typography>
      Note: The memories reset when the page is refreshed. This is a work in progress. The memories in here are not real, just some random ones I'm using to test the website.


      <TextField
  fullWidth
  margin="normal"
  label="Filter memories"
  value={filter}
  onChange={(e) => setFilter(e.target.value)}
/>
<Button onClick={() => setFilter('')}>Clear Filter</Button>

      <Grid container spacing={4}>
        {filteredMemories.map((memory) => (
          <Grid item key={memory.id} xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography variant="body1">
                  {memory.content}
                </Typography>
                {memory.tags.map((tag) => (
                  <Chip key={tag} label={tag} className={classes.chip} size="small" />
                ))}
              </CardContent>
              <CardActions>
                <IconButton aria-label="like" onClick={() => handleLike(memory.id)}>
                  {memory.likes > 0 ? <Favorite color="secondary" /> : <FavoriteBorder />}
                </IconButton>
                <Typography variant="body2">{memory.likes} likes</Typography>
                {memory.isJoke && <EmojiEmotions color="primary" />}
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Button
        variant="contained"
        color="primary"
        className={classes.addButton}
        startIcon={<Add />}
        onClick={handleClickOpen}
      >
        Add Memory
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Memory</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="content"
            label="Memory or Joke"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={newMemory.content}
            onChange={handleNewMemoryChange}
          />
          <TextField
            margin="dense"
            name="tags"
            label="Tags (comma-separated)"
            type="text"
            fullWidth
            value={newMemory.tags}
            onChange={handleNewMemoryChange}
          />
          <Button
            onClick={() => setNewMemory(prev => ({ ...prev, isJoke: !prev.isJoke }))}
            color={newMemory.isJoke ? "secondary" : "default"}
            startIcon={<EmojiEmotions />}
          >
            {newMemory.isJoke ? "This is a joke" : "Mark as joke"}
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleNewMemorySubmit} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default MemoriesPage;