import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Dialog, Typography, Paper } from '@material-ui/core';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import FavoriteIcon from '@material-ui/icons/Favorite';
import StarIcon from '@material-ui/icons/Star';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  timelineContent: {
    padding: '12px 16px',
  },
  timelineImage: {
    width: '100%',
    maxWidth: '300px',
    borderRadius: '4px',
    marginTop: theme.spacing(2),
  },
}));

const timelineEvents = [
  {
    date: 'Summer, 2018',
    title: 'Hope Helps',
    description: 'We had made eye contact with our mirror soul. I knew from that moment that you were special. The universe has exhaled, and we both went our separate ways. The cosmic longing began: two twin flames floating in the universe and are seeking to intertwine.',
    icon: <FavoriteIcon />,
    image: 'https://i.imgur.com/wuNlmBK.jpeg',
  },
  {
    date: 'Summer, 2021',
    title: 'Reunion',
    description: 'The universe has inhaled once more, and our souls have reunited and combined into a single harmonic resonance. Our bond has left a stamp in the fabric of space and time.',
    icon: <StarIcon />,
    image: 'https://i.imgur.com/hyLTgEp.jpeg',
  },
  {
    date: 'September 06, 2021 (69)',
    title: 'The birth of Bean and Fishie',
    description: 'This is the day the I have asked you to be mine. This day marked the beginning of the greatest relationship of all time. Our first official date was at Arcade Monsters. I will never forget the joy I\'ve seen in your eyes that day (especially when we enjoyed our first official kiss together). I will never forget the games we played together (especially the ones I beat you in). Also this picture isn\'t from our first date but it\'s one of my all-time favorites',
    icon: <EmojiEventsIcon />,
    image: 'https://i.imgur.com/lXjwazQ.jpeg',
  },
  {
    date: 'May 30, 2022',
    title: 'Seattle',
    description: 'This was the biggest challenge we have faced thus far. I moved thousands of miles away for three months in order to advance my career. The goal has always been to be in a position to provide us with the life we\'ve always dreamt of; I just never realized this at the time. Although the distance between us was large, our love and longing for each other has grown even larger. I pushed through and came back triumphant with your love and support, and I love you so much for that. This picture is from our last time seeing each other before my flight <3',
    icon: <FavoriteIcon />,
    image: 'https://i.imgur.com/SvCivWt.jpeg',
  },
  {
    date: 'September 6th, 2022',
    title: 'First Anniversary <3',
    description: 'This day marked our first anniversary. This is a day I will never forget. We went to international drive and got some amazing Arabic food. That night you used the power of "superiority" and bought us some white claws because I was baby aged. We booked our first ever hotel room and had the best time of our lives together (wink, wink you are very pretty and sexy). Not putting a picture here for obvious reasons ;))))',
    icon: <EmojiEventsIcon />,
    image: 'https://example.com/first-date.jpg',
  },
  {
    date: 'December 15th, 2022',
    title: 'Bahamas Cruise (MMMMMAI TAI)',
    description: 'This is our first vacation together. I have enjoyed every single moment of this vacation with you my love. We rode a JetSki together, we had some amazing food, visited a new country, hung out at the beach, and had fun dancing (rather horribly lol). I have many memorable moments: When we danced together to the hispanic music, I was envisioning us dancing at our wedding. Our antics in the Bahamas (including the iconic cigar + bahama mamas) showed me the side of you that can have careless fun. When you got seasick, I enjoyed caring for you even though I was worried sick. You are the only vacation buddy I want with me.',
    icon: <StarIcon />,
    image: 'https://i.imgur.com/gaF616G.jpeg',
  },
  {
    date: 'July 7th, 2023',
    title: 'New Jersey',
    description: 'This is the last day I saw you before I went to New Jersey. We said our painful goodbyes and I was gut-wrenched. We went to Burlington\'s that day and also got some food. This marks the beginning of my adult life and it was a very difficult adjustment to make. I am grateful to have had your support during that time. This proved to be the most difficult challenge that we\'ve endured.',
    icon: <StarIcon />,
    image: 'https://i.imgur.com/VAmE9Jk.jpeg',
  },
  {
    date: 'September 6th, 2023',
    title: '2nd Anniversary',
    description: 'This is the worst moment of my life so far. I stood face-to-face across from death with no help or support. I almost lost the love of my life. The love of my life was going through one of the toughest moments of her life too. I wish I could\'ve done things differently. I love you endlessly and I learned many valuable lessons from this experience.',
    icon: <EmojiEventsIcon />,
    image: 'https://i.imgur.com/X4fha4y.jpeg',
  },
  {
    date: 'December 6th, 2023',
    title: 'Bean Graduation',
    description: 'This was one of the happiest moments of our relationship. Bean has graduated and became a NURSE. I cannot describe the joy and pride I was feeling at the time. You looked so pretty and I wish that I could\'ve made it there to give you a kiss and a hug my love.',
    icon: <StarIcon />,
    image: 'https://i.imgur.com/WQboCFY.png',
  },
  {
    date: 'December 15th, 2023',
    title: 'First Visit',
    description: 'This was Bean\'s first flight and I was extremely excited at the time to see the love of my life once more. My most memorable moment from that visit was when we walked through Byrant park during Christmas time. Top contentders for favorite moment are: liberty statue cruise, Korean BBQ. This was the first experience we had with living in the same place and I was the happiest man on earth just thinking about it.',
    icon: <StarIcon />,
    image: 'https://i.imgur.com/2bgoZD4.jpeg',
  },
  {
    date: 'April 20th, 2024 (420 but we didn\'t blaze it)',
    title: 'Second Visit',
    description: 'This was the time when you bought and left some clothes at my apartment. The love of my life is slowly moving in with me! I could not believe this, how could I be so lucky?! My favorite moment during that visit is when we walked amongst the Cherry Blossom trees. This is the most peace I felt in my life.',
    icon: <StarIcon />,
    image: 'https://i.imgur.com/RXRo5nh.jpeg',
  },
  {
    date: 'June 1st, 2024',
    title: 'Third Visit',
    description: 'I was at the height of my depression at the time and was overwhelmed with life. I shared with you my deepest shame and you held me tight. We still had a great vacation to Ringwood state park. I enjoyed protecting you from these annoying bugs. I remember we almost crashed a wedding XD!!! One day it will be you and I\'s special day <3. REMEMBER THAT GUY DOING HIS ZEN BS??? that dude bothered me so much lmfaooo',
    icon: <StarIcon />,
    image: 'https://i.imgur.com/1WMd7rr.jpeg',
  },
  {
    date: 'September 6th, 2024',
    title: 'Third Anniversary',
    description: 'SURPRISE <3 MWAHH I CAN\'T WAIT',
    icon: <StarIcon />,
    image: '',
  },
];

function TimelinePage() {
  const classes = useStyles();
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  return (
    <Container className={classes.container} maxWidth="md">
      <Typography variant="h2" align="center" gutterBottom>
        Bean and Fishie's Journey in this Crazy World
      </Typography>
      <Typography variant="h5" align="center" color='textSecondary' gutterBottom>
        A running list of our most important moments together. There are too many moments and milestones to count, so I will keep adding to past moments and will add future moments as we encounter them.
      </Typography>
      <Timeline align="alternate">
        {timelineEvents.map((event, index) => (
          <TimelineItem key={index}>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                {event.date}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color="primary">
                {event.icon}
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                  {event.title}
                </Typography>
                <Typography>{event.description}</Typography>
                {event.image && (
                  <img
                    src={event.image}
                    alt={event.title}
                    className={classes.timelineImage}
                    onClick={() => handleImageClick(event.image)}
                    style={{ cursor: 'pointer' }}
                  />
                )}
              </Paper>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
      <Dialog open={!!selectedImage} onClose={handleClose}>
        <img src={selectedImage} alt="Zoomed" style={{ width: '100%' }} />
      </Dialog>
    </Container>
  );
}

export default TimelinePage;