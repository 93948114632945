import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Header from './components/Header';
import LandingPage from './pages/LandingPage';
import Timeline from './pages/Timeline';
import Gallery from './pages/Gallery';
import Messages from './pages/Messages';
import Memories from './pages/Memories';
import Playlist from './pages/Playlist';
import { Grid, Typography } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#C8A2C8',
    },
    secondary: {
      main: '#1976d2',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Grid container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}} >
        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
            too late...
          </Typography>
      </Grid>
      {/* <Router>
        <Header />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/timeline" element={<Timeline />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/memories" element={<Memories />} />
          <Route path="/playlist" element={<Playlist />} />
        </Routes>
      </Router> */}
    </ThemeProvider>
  );
}

export default App;