import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Typography,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Grid,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { PlayArrow, Pause, Add, MusicNote } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  card: {
    display: 'flex',
    margin: theme.spacing(2, 0),
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 151,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  addButton: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

// Replace with your Spotify API credentials
const SPOTIFY_CLIENT_ID = '51740c37d8094c58864dd773908ebf2b';
const SPOTIFY_CLIENT_SECRET = 'def52564a94449578bad1dd34df01b2f';

function PlaylistPage() {
  const classes = useStyles();
  const [playlist, setPlaylist] = useState([]);
  const [open, setOpen] = useState(false);
  const [newSong, setNewSong] = useState({ title: '', artist: '', meaning: '' });
  const [spotifyToken, setSpotifyToken] = useState('');
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);

  useEffect(() => {
    // Get Spotify access token
    const getSpotifyToken = async () => {
      const response = await axios.post('https://accounts.spotify.com/api/token', 
        'grant_type=client_credentials', 
        {
          headers: {
            'Authorization': 'Basic ' + btoa(SPOTIFY_CLIENT_ID + ':' + SPOTIFY_CLIENT_SECRET),
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      );
      setSpotifyToken(response.data.access_token);
    };

    getSpotifyToken();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setNewSong({ title: '', artist: '', meaning: '' });
  };

  const handleNewSongChange = (event) => {
    const { name, value } = event.target;
    setNewSong((prevSong) => ({
      ...prevSong,
      [name]: value,
    }));
  };

  const handleNewSongSubmit = async () => {
    try {
      const searchResponse = await axios.get(`https://api.spotify.com/v1/search`, {
        params: {
          q: `${newSong.title} ${newSong.artist}`,
          type: 'track',
          limit: 1
        },
        headers: {
          'Authorization': `Bearer ${spotifyToken}`
        }
      });

      const trackInfo = searchResponse.data.tracks.items[0];
      
      if (trackInfo) {
        setPlaylist([...playlist, {
          id: trackInfo.id,
          title: trackInfo.name,
          artist: trackInfo.artists[0].name,
          albumCover: trackInfo.album.images[0].url,
          previewUrl: trackInfo.preview_url,
          meaning: newSong.meaning
        }]);
      }

      handleClose();
    } catch (error) {
      console.error('Error adding song:', error);
    }
  };

  const togglePlay = (song) => {
    if (currentlyPlaying && currentlyPlaying.id === song.id) {
      currentlyPlaying.audio.pause();
      setCurrentlyPlaying(null);
    } else {
      if (currentlyPlaying) {
        currentlyPlaying.audio.pause();
      }
      const audio = new Audio(song.previewUrl);
      audio.play();
      setCurrentlyPlaying({ id: song.id, audio });
    }
  };

  return (
    <Container className={classes.container} maxWidth="md">
      <Typography variant="h2" align="center" gutterBottom>
        Our Playlist
      </Typography>
      Note: This page is a work in progress. The song list does not persist between sessions and the playback functionality doesn't work. I am having problems with the Spotify integration.
      <iframe style={{ borderRadius: "12px" }} src="https://open.spotify.com/embed/playlist/37i9dQZF1EJwYwfSgzfZvj?utm_source=generator" width="100%" height="600" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
      {playlist.map((song) => (
        <Card className={classes.card} key={song.id}>
          <CardMedia
            className={classes.cover}
            image={song.albumCover}
            title={song.title}
          />
          <div className={classes.details}>
            <CardContent className={classes.content}>
              <Typography component="h5" variant="h5">
                {song.title}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                {song.artist}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Meaning: {song.meaning}
              </Typography>
            </CardContent>
            <div className={classes.controls}>
              <IconButton aria-label="play/pause" onClick={() => togglePlay(song)}>
                {currentlyPlaying && currentlyPlaying.id === song.id ? 
                  <Pause className={classes.playIcon} /> : 
                  <PlayArrow className={classes.playIcon} />}
              </IconButton>
            </div>
          </div>
        </Card>
      ))}

      <Button
        variant="contained"
        color="primary"
        className={classes.addButton}
        startIcon={<Add />}
        onClick={handleClickOpen}
      >
        Add Song
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Song</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="title"
            label="Song Title"
            type="text"
            fullWidth
            value={newSong.title}
            onChange={handleNewSongChange}
          />
          <TextField
            margin="dense"
            name="artist"
            label="Artist"
            type="text"
            fullWidth
            value={newSong.artist}
            onChange={handleNewSongChange}
          />
          <TextField
            margin="dense"
            name="meaning"
            label="Meaning to Us"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={newSong.meaning}
            onChange={handleNewSongChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleNewSongSubmit} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default PlaylistPage;